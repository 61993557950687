@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@900&display=swap');
.App {
  text-align: center;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  font-family: 'Source Code Pro', monospace;
  background-color: black;
}


@media screen and (max-width: 820px) {
  .App {
    flex-direction: column;
  }

  .left {
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 1.5rem !important;
    height: 40vh !important;
    min-height: 40vh !important;
    max-height: 40vh !important;
  }

  .right {
    height: 60vh !important;
    min-height: 60vh !important;
    max-height: 60vh !important;
  }

  .mini-guess {
    width: 8.5vh !important;
    height: 8.5vh !important;
  }

  .guess-right .mini-guess {
    margin-right: 1em !important;
  }

  .mini-guess.reset {
    width: fit-content !important;
    height: unset !important;
  }

  .mini-guess span {
    line-height: 8vh !important;
  }

  .floating-corner {
    flex-direction: row !important;
  }

  .slider-label {
    text-shadow: 2px 2px 5px black;
    font-size: 1.3rem !important;
    margin-left: 1rem !important;
  }

  .ad-container {
  }

  .guess-container {
    margin: 1em 0 !important;
  }

  .guess-container .values.sliders {
    margin-left: 1em !important;
  }
  
  .guess-preview {
    flex-direction: column !important;
  }

  .gp {
    width: 6em !important;
    height: 6em !important;
  }

  .gp-left {
    border-top-left-radius: 0.3em !important;
    border-top-right-radius: 0.3em !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .gp-right {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0.3em !important;
    border-bottom-right-radius: 0.3em !important;
  }
}

@media screen and (max-width: 365px) {
  .preview-label {
    font-size: 1.75rem !important;
    line-height: 1.75rem;
  }

  input[type=number].num {
    width: 3.5rem;
    font-size: 1.75rem;
  }

  .footer {
    font-size: 0.8rem;
  }

  input[type=range]:focus::-webkit-slider-thumb {
    border-top: 9px solid white !important;
  }
}

.text-white {
  color: white;
}

.mini-guess.reset {
  font-weight: bold;
  font-size: 1.6rem;
  background-color: black;
  width: fit-content;
  color: white;
  padding: 5px 10px;
  aspect-ratio: unset;
  height: unset;
  margin: auto;
  margin-top: .6rem;
}

.left, .right {
  display: flex;
  flex-direction: column;
}

.left {
  font-family: 'Source Code Pro', monospace;
  font-size: 3rem;
  text-shadow: 2px 2px 5px black;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
  background-color: #111111;
  height: 100vh;
  overflow: hidden;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.vertical-container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mini-guess {
  width: 10vh;
  height: 10vh;
  margin-right: 3.5px;
  aspect-ratio: 1;
  border-radius: .5rem;
}

.mini-guess.active {
  animation: spin 16s ease infinite;
}

.mini-guess span {
  line-height: 10vh;
  vertical-align: middle;
  font-size: 1.3rem;
  text-shadow: 2px 2px 5px black;
}

.mini-guess.btn {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mini-guess.btn:hover {
  background-color: white;
  color: black;
  font-weight: bold;
  border-color: black;
  text-shadow: none;
}

.preview-label {
  color: white;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 3.5rem;
}

.right {
  font-family: 'Source Code Pro', monospace;
  flex-basis: 60%;
  background-color: black;
  min-height: calc(100vh - 1em);
  flex-direction: column;
}

.right-inner {
  max-height: calc(100vh - 1em);
  margin-bottom: 1em;
  overflow-y: auto;
  overflow-x: hidden;
}

.guess {
  height: fit-content;
  border: 1px solid gray;
  background-color: #222222;
  border-radius: 0.5em;
}

.guess-container {
  align-items: flex-start;
  height: 100%;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 1em;
}

.guess-container .values {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1.5rem;
  width: 80%;
}

.guess-container.inputs {
  font-size: 2.5rem;
}

.guess-container .bump {
  font-size: 1.7rem;
  font-weight: bold;
  cursor: pointer;
  width: 1.7rem;
}

.guess-container .bump.disabled {
  color: #666;
  cursor: pointer;
  pointer-events: none;
}

.guess-container .bump:hover {
  color: rgb(128, 128, 204) !important;
}

.guess-container .bump.down {
  margin-left: 0.5em;
}


.guess-container .bump.up {
  margin-right: 0.5em;
}

.guess-container .values.inputs .label {
  display: none;
}

.guess-container .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 1.7em;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.guess-container .values.inputs input {
  color: white;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 5px black;
  margin-right: 1rem;
  border-color: transparent;
}

input[type=number].num.r:disabled {
  background-color: red;
}
input[type=number].num.g:disabled {
  background-color: green;
}
input[type=number].num.b:disabled {
  background-color: blue;
}

input[type=number]:disabled {
  color: unset;
}
input[type=number]:disabled.close {
  color: unset;
}

input[type=number]:focus {
  outline: none;
}

.expand-hover:hover {
  transform: scale(1.1) !important;
}

.guess-container .values.inputs input.r {
  background-color: red;
}
.guess-container .values.inputs input.g {
  background-color: green;
}
.guess-container .values.inputs input.b {
  background-color: blue;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0; 
}

.guess-container .values.sliders {
  flex-direction: column;
}

.guess-right {
  width: 20%;
  display: flex;
  justify-content: center;
}

.result-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-weight: bold;
}

.result-container.vertical {
  flex-direction: column;
  margin: 0.5em 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-1 {
  margin-right: 0.25em;
  margin-left: 0.25em;
}

.label {
  color: white;
}

.label.small {
  font-size: 0.45em;
}

.label.r {
  color: red;
}

.label.g {
  color: green;
}

.label.b {
  color: blue;
}

.num {
  width: 4rem;
  font-size: 2rem;
}

.num.sliders {
  width: calc(100% - 1.5rem);
}

.num.sliders.submitted {
  width: calc(100% - 4.2rem);
}

.num.sliders {
  overflow: hidden;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
  height: 1.2rem;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 0;
  cursor: pointer;
  margin-top: -4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;

  border-top: 9px solid white;
}

.num.sliders.submitted::-webkit-slider-thumb {
  border-top-color: #aaa;
}

.num.sliders.close::-webkit-slider-thumb {
  border-top-color: rgb(255, 255, 111) !important;
}

.num.sliders.correct::-webkit-slider-thumb {
  border-top-color: rgb(56, 163, 56) !important;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: .5rem;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  height: 20px;
  width: 20px;
  border-radius: .5rem;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  height: 12px;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 14px;
  border-radius: .5rem;
  border: 0.08rem solid rgba(255, 255, 255, 0.4);
}

input[type="range"].close::-webkit-slider-runnable-track {
  border: 0.08rem solid rgb(255, 255, 111);
}
input[type="range"].correct::-webkit-slider-runnable-track {
  border: 0.08rem solid rgb(34 220 34);
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  border: 0.1rem solid rgb(128, 128, 204);
}

.num.sliders.r::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, darkred, red);
}

.num.sliders.g::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, darkgreen, green);
}

.num.sliders.b::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, darkblue, blue);
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  height: 12px;
  border-radius: .5rem;
}

.num.sliders.r::-moz-range-track {
  background: red;
}

.num.sliders.g::-moz-range-track {
  background: green;
}

.num.sliders.b::-moz-range-track {
  background: blue;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.mr-1 {
  margin-right: .1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.close {
  color: rgb(255, 255, 111) !important;
}

.correct {
  color: rgb(34 220 34) !important;
}

.floating-corner {
  position: fixed;
  top: .3rem;
  left: .3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider-label {
  color: white;
  text-shadow: 2px 2px 5px black;
  font-weight: bold;
  margin-top: .25rem;
  margin-left: .25rem;
  font-size: 2rem;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 26px;
  border-radius: .5rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: .5rem;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 2rem;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}


/* Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkblue;
  border-radius: .5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(11, 11, 161);
}



/** ANIMATIONS **/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(359deg);
  }
  65% {
    transform: rotate(0deg);
  }
  99% {
    transform: rotate(0deg);
  }
}

.pulser {
  animation: pulse 6s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    transform-origin: bottom;
  }
  25% {
    transform: scale(1);
    transform-origin: bottom;
  }
  50% {
    transform: scale(1.04);
    transform-origin: bottom;
  }
  75% {
    transform: scale(1);
    transform-origin: bottom;
  }
  99% {
    transform: scale(1);
    transform-origin: bottom;
  }
}

.ad-container {
  width: 100%;
}

/* ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
} */

/** Footer **/

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #666;
  border-top: 2px solid #333;
}

.footer a {
  color: white !important;
}

@media screen and (max-width: 290px) {
  .footer {
    font-size: 0.5rem;
  }

  .guess-right .mini-guess.btn {
    margin-right: 1.3em !important;
  }
}

.mute-icon {
  color: white;
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  filter: invert(100%) drop-shadow(5px 3px 3px rgba(0, 0, 0, .5));
  cursor: pointer;
  text-shadow: 2px 2px 5px black;
}

/** iOS **/
.App.ios {
  flex-direction: column;
  max-height: 100vh;
}

.App.ios {
  overflow: hidden;
}

.App.ios .left {
  flex-basis: 40%;
}

.App.ios .right {
  flex-basis: 60%;
}

.App.ios .right-inner {
  margin-bottom: 10em;
}

.App.ios .num.sliders.submitted.close::-webkit-slider-thumb {
  border-top-color: rgb(255, 255, 111) !important;
}

.App.ios .num.sliders.submitted.correct::-webkit-slider-thumb {
  border-top-color: rgb(56, 163, 56) !important;
}

.App.ios input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: initial;
  margin-top: -2px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

  width: 15px;
  height: 15px;
  border-left: unset;
  border-right: unset;
  border-top: 15px solid white;
}

.App.ios input[type=number].num {
  width: 3.5rem;
  font-size: 1.75rem;
}


/** MODAL **/
.modal-outer {
  background-color: rgba(60, 60, 60, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  display: flex;
}

.modal-inner {
  width: fit-content;
  background-color: #222;
  border: 0.1em solid white;
  padding: 1.5em 2em 2em;
  border-radius: 0.5em;
  margin: auto;
  position: relative;
}

.guess-preview {
  display: flex;
  flex-direction: row;
  border: 0.1em solid #ccc;
  border-radius: 0.5em;
  text-shadow: 2px 2px 5px black;
}

.close-modal {
  position: absolute;
  top: 0.1em;
  right: 0.6em;
  cursor: pointer;
  color: red;
}

.modal-title {
  color: white;
}

.gp {
  width: 15em;
  height: 15em;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.gp-left {
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
}

.gp-right {
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
}
